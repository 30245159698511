import React from "react"
import Img from "gatsby-image"

import * as C from "shared/components"

import spiral from "images/spiral.svg"

import ScrollMagicWrapper from "../ScrollMagic"
import { CopyHeading, Crescent } from "../styled"
import * as S from "./styled"

export const LightSection = ({ content }) => {
  const { Light_Section_Text: lightSectionText } = content

  return (
    <ScrollMagicWrapper triggerElement="#lightSectionTrigger">
      <C.ContentWrapper>
        <div id="lightSectionTrigger" />
        <S.StyledLightSection>
          <CopyHeading maxWidth="30.5rem">{lightSectionText}</CopyHeading>
          <S.RightColumn>
            <S.SpiralImage src={spiral} alt="" />
            <S.ImageWrapper>
              <Crescent width={52} dir="left" right="1%" top="52%" />
            </S.ImageWrapper>
          </S.RightColumn>
        </S.StyledLightSection>
      </C.ContentWrapper>
    </ScrollMagicWrapper>
  )
}
