import React from "react"
import Img from "gatsby-image"

import * as C from "shared/components"

import ScrollMagicWrapper from "../ScrollMagic"
import { CopyHeading, Crescent } from "../styled"
import * as S from "./styled"

const buttonOverrides = { paddingLeft: "2.75rem", paddingRight: "2.75rem" }

export const DarkSection = ({ content }) => {
  const {
    Dark_Section_Text: darkSectionText,
    Dark_Section_CTA: darkSectionCta,
  } = content
  return (
    <ScrollMagicWrapper triggerElement="#darkSectionTrigger">
      <div id="darkSectionTrigger" />
      <S.StyledDarkSection>
        <S.ImageWrapper>
          <Crescent width={64} top="12%" right="23%" dir="left" />
        </S.ImageWrapper>
        <S.DarkSectionWrapper>
          <S.DarkSectionContent>
            <CopyHeading maxWidth="27.5rem" color="light">
              {darkSectionText}
            </CopyHeading>
            <C.Button
              id="second-build-persona-button"
              to="/persona"
              fontFamily="ttCommons"
              color="white"
              overrides={buttonOverrides}
            >
              {darkSectionCta}
            </C.Button>
          </S.DarkSectionContent>
        </S.DarkSectionWrapper>
      </S.StyledDarkSection>
    </ScrollMagicWrapper>
  )
}
