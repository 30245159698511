import React from "react"
import Img from "gatsby-image"

import ScrollMagicWrapper from "../ScrollMagic"
import { CopyHeading } from "../styled"
import * as S from "./styled"

export const PersonsSection = ({ content }) => {
  const { Pink_Section_Text: pinkSectionText } = content
  return (
    <S.StyledPersonsSection>
      <ScrollMagicWrapper triggerElement="#personsTrigger">
        <div id="personsTrigger" />
        <S.LeftSection>
          <S.ImageWrapper>
          </S.ImageWrapper>
        </S.LeftSection>
        <S.PersonsSectionWrapper>
          <S.PersonsSectionContent>
            <CopyHeading>{pinkSectionText}</CopyHeading>
          </S.PersonsSectionContent>
        </S.PersonsSectionWrapper>
      </ScrollMagicWrapper>
    </S.StyledPersonsSection>
  )
}
