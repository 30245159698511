import styled from "styled-components"
import examplePersonasBg from "images/example-personas-bg.svg"

export const ExamplePersonasWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background: url(${examplePersonasBg});
  background-size: cover;
  background-position: center;
  margin-top: 5rem;
`

export const ImageWrapper = styled.div`
  width: 75.5rem;
`
