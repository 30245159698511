import React from "react"
import Img from "gatsby-image"

import * as C from "shared/components"
import { Crescent } from "../styled"

import * as S from "./styled"

const buttonOverrides = {
  paddingLeft: "2.75rem",
  paddingRight: "2.75rem",
  fontWeight: 400,
}

export const Hero = ({ content }) => {
  const {
    Hero_CTA_Text: heroCtaText,
    Hero_Claim: heroClaim,
    Hero_Description: heroDescription,
  } = content

  return (
    <>
      <S.Hero>
        <S.HeroContent>
          <S.Claim>{heroClaim}</S.Claim>
          <S.Description>{heroDescription}</S.Description>
        </S.HeroContent>
        <C.Button
          id="intro-build-persona-button"
          to="/persona"
          fontFamily="ttCommons"
          color="darkGreyBlue"
          overrides={buttonOverrides}
        >
          <S.ButtonLabel>{heroCtaText}</S.ButtonLabel>
        </C.Button>
      </S.Hero>
      <S.ImageWrapper>
        <Crescent
          width={78}
          right="2.5%"
          top="38%"
          dir="left"
          transform="translateY(-50%)"
        />
      </S.ImageWrapper>
    </>
  )
}
