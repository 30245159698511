import React from "react"
import Img from "gatsby-image"

import ScrollMagicWrapper from "../ScrollMagic"
import { CopyHeading } from "../styled"
import * as S from "./styled"

export const DownloadSection = ({ content }) => {
  const { Download_Section_Text: downloadSectionText } = content

  return (
    <S.StyledDownloadSection>
      <ScrollMagicWrapper triggerElement="#downloadSectionTrigger">
        <S.ImageWrapper>
        </S.ImageWrapper>
        <S.DownloadSectionWrapper>
          <S.DownloadSectionContent>
            <CopyHeading>{downloadSectionText}</CopyHeading>
          </S.DownloadSectionContent>
        </S.DownloadSectionWrapper>
      </ScrollMagicWrapper>
    </S.StyledDownloadSection>
  )
}
