import React from "react"
import Img from "gatsby-image"

import * as C from "shared/components"

import { CopyHeading } from "../styled"
import ScrollMagicWrapper from "../ScrollMagic"

import * as S from "./styled"

export const ExamplePersonas = ({ content }) => {
  return (
    <>
      <C.ContentWrapper>
        <div id="trigger" />
        <ScrollMagicWrapper triggerElement="#trigger">
          <CopyHeading maxWidth="34rem" color="dark">
            {content.Intro_Text}
          </CopyHeading>
        </ScrollMagicWrapper>
      </C.ContentWrapper>
      <S.ExamplePersonasWrapper>
        <div id="examplePersonasTrigger" />
        <ScrollMagicWrapper triggerElement="#examplePersonasTrigger">
          <S.ImageWrapper>
          </S.ImageWrapper>
        </ScrollMagicWrapper>
      </S.ExamplePersonasWrapper>
    </>
  )
}
